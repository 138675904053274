import { Course } from "./scripts/courses";
import "./styles/CourseTop.css";
import { setCourseField } from "./scripts/fields";
import { useParams } from "react-router-dom";
import { NavigateFunction } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { addStudent } from "./scripts/purchases";
import { useOverlay } from "../../context/OverlayProvider";
import OverlayWindow from "../overlay/OverlayWindow";
import Payment from "./Payment";
import { useTranslation } from "react-i18next";

type props = {
  course: Course | null;
  navigate: NavigateFunction;
  isPurchased: boolean;
  isAccepted: boolean;
  hasApplied: boolean;
  setPurchaseStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setCallRegister: React.Dispatch<React.SetStateAction<boolean>>;
};

function CourseTop(props: props) {
  const { course, isPurchased, isAccepted, hasApplied, setCallRegister } =
    props;
  const { courseid } = useParams();
  const { admin, user } = useAuth();
  const [, setOverlay] = useOverlay();
  const { t } = useTranslation();

  const handleApply = async () => {
    await addStudent("applicants", courseid!, user?.email!);
    window.open(course?.signupForm);
  };

  const handlePayment = async () => {
    setOverlay((overlay) => {
      return {
        ...overlay,
        content: (
          <OverlayWindow>
            <Payment
              course={course!}
              setPurchaseStatus={props.setPurchaseStatus}
            />
          </OverlayWindow>
        ),
        display: "flex",
      };
    });
  };

  return (
    <div className="background">
      <div className="lastcourse-courses-page">
        <div className="return" onClick={() => props.navigate("/")}>
          <img
            src={process.env.PUBLIC_URL + "/img/return.png"}
            alt="Retunr"
          ></img>
          <div>{t("Back to main menu")}</div>
        </div>
        <div className="videoThumbnail-courses-page">
          <div className="mediaArea-courses-page">
            {course &&
              (course.video.endsWith(".mp4") ? (
                <video
                  width="100%"
                  height="auto"
                  controls
                  controlsList="nodownload"
                  onContextMenu={(e) => e.preventDefault()}
                  autoPlay
                  muted
                  loop
                >
                  <source src={course.video} type="video/mp4"></source>
                </video>
              ) : (
                <img
                  width="100%"
                  height="auto"
                  src={
                    course.thumbnail ||
                    process.env.PUBLIC_URL + "/img/no-image.jpeg"
                  }
                  alt="Thumbnail"
                ></img>
              ))}
          </div>
        </div>
      </div>
      <div className="right-area">
        <div className="vertical-grid-container-courses-page">
          <div
            className="title-case"
            contentEditable={admin}
            placeholder="Titulo"
            onBlur={(e) =>
              setCourseField(courseid || "", "title", e.target.innerText)
            }
            suppressContentEditableWarning
          >
            {course?.title}
          </div>
          <div
            className="subtitle-case"
            contentEditable={admin}
            placeholder="Subtítulo"
            onBlur={(e) =>
              setCourseField(courseid || "", "subtitle", e.target.innerText)
            }
            suppressContentEditableWarning
          >
            {course?.subtitle}
          </div>
          <div style={{ display: "none" }} className="calendar-container">
            <div>Inicia en:</div>
            <div className="calendar">
              <div className="item-calendar">
                <div className="number">00</div>
                <div className="text">DIAS</div>
              </div>
              <div className="point-calendar">:</div>
              <div className="item-calendar">
                <div className="number">00</div>
                <div className="text">HORAS</div>
              </div>
              <div className="point-calendar">:</div>
              <div className="item-calendar">
                <div className="number">00</div>
                <div className="text">MINUTOS</div>
              </div>
              <div className="point-calendar">:</div>
              <div className="item-calendar">
                <div className="number">00</div>
                <div className="text">SEGUNDOS</div>
              </div>
            </div>
          </div>

          {(!admin && !isPurchased) ? (
            <>
              <div
                className="button-case"
                style={hasApplied ? { backgroundColor: "gray" } : {}}
                onClick={() => {
                  if (!user) setCallRegister(true);
                  else if (!isPurchased) {
                    if (
                      isAccepted ||
                      !course?.signupForm ||
                      course.signupForm === ""
                    )
                      handlePayment();
                    else if (!hasApplied) handleApply();
                    else if (hasApplied) window.open(course?.signupForm);
                  }
                }}
              >
                <div className="text-button-container">
                  <div>
                    {user
                      ? isPurchased
                        ? t("CONTINUE")
                        : isAccepted ||
                          !course?.signupForm ||
                          course.signupForm === ""
                        ? t("MAKE PAYMENT")
                        : hasApplied
                        ? t("ALREADY APPLIED")
                        : t("APPLY NOW!")
                      : t("REGISTER NOW")}
                  </div>
                  {isPurchased && (
                    <img
                      src={process.env.PUBLIC_URL + "/img/triangle-icon.png"}
                      alt="Triangle"
                    ></img>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default CourseTop;
